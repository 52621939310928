<template>
    <div>
        <NavbarDesktopAccountActions
            :username="username"
            my-account-href="/my-account"
            :balance="balance"
            :world-cams="inWorldCams"
            @update-balance="updateBalance"
            :updating-balance="updatingBalance"
            mode="intertia"
        />
    </div>
</template>

<script setup>
import { NavbarDesktopAccountActions } from '@babestationui/vue'

import { useStore } from 'vuex'

import { computed, ref } from 'vue'

const store = useStore()

const username = store.state.user.info.username

const balance = computed(() =>
    inWorldCams.value
        ? store.getters['user/worldCamsBalance']
        : store.getters['user/balance']
)

const updatingBalance = ref(false)

const inWorldCams = computed(() => {
    return store.state.livecams.inWorldCams
})

const updateBalance = async () => {
    updatingBalance.value = true

    await store.dispatch('user/fetchBalance')

    updatingBalance.value = false
}
</script>
