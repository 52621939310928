<template>
    <footer class="bg-dark-brand" id="footer">
        <div
            class="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8"
        >
            <nav
                class="-mx-5 -my-2 flex flex-wrap justify-center"
                aria-label="Footer"
            >
                <div class="px-5 py-2">
                    <a
                        href="/"
                        class="text-base text-gray-500 hover:text-gray-200"
                    >
                        Home</a
                    >
                </div>
                <div class="px-5 py-2">
                    <a
                        href="https://twitter.com/babestationtv"
                        class="text-base text-gray-500 hover:text-gray-200"
                    >
                        On Twitter
                    </a>
                </div>

                <div class="px-5 py-2">
                    <a
                        href="https://www.babestation.tv/blog/"
                        class="text-base text-gray-500 hover:text-gray-200"
                        title="Home of Babeshows"
                    >
                        Official Blog
                    </a>
                </div>

                <div class="px-5 py-2">
                    <a
                        href="/faqs"
                        class="text-base text-gray-500 hover:text-gray-200"
                    >
                        FAQs
                    </a>
                </div>

                <div class="px-5 py-2">
                    <a
                        href="/terms"
                        class="text-base text-gray-500 hover:text-gray-200"
                    >
                        Terms
                    </a>
                </div>

                <div class="px-5 py-2">
                    <a
                        href="/privacy"
                        class="text-base text-gray-500 hover:text-gray-200"
                    >
                        Privacy Policy
                    </a>
                </div>

                <div class="px-5 py-2">
                    <a
                        href="https://recruitment.babestation.tv"
                        class="text-base text-gray-500 hover:text-gray-200"
                    >
                        Become a Babestation Model
                    </a>
                </div>

                <div class="px-5 py-2">
                    <a
                        href="/content-removal"
                        class="text-base text-gray-500 hover:text-gray-200"
                    >
                        Content Removal
                    </a>
                </div>

                <div class="px-5 py-2">
                    <a
                        href="/end-user-transaction-terms"
                        class="text-base text-gray-500 hover:text-gray-200"
                    >
                        End User Transaction Terms
                    </a>
                </div>
                <div class="px-5 py-2">
                    <a
                        href="/end-user-terms"
                        class="text-base text-gray-500 hover:text-gray-200"
                    >
                        End User Terms
                    </a>
                </div>
            </nav>
            <p class="mt-8 text-center text-base text-gray-400">
                &copy; 2002 - {{ new Date().getFullYear() }} Babestation. All
                rights reserved.
                <br />
                This is a site of
                <span class="obfuscate-text"> {{ companyName }} </span>
            </p>

            <p class="text-sm text-gray-600 text-center mt-2">
                <em>
                    All models featured on this site were 18 years old or older
                    at time of photography.
                    <br />
                    <a href="/2257" class="hover:text-gray-400">
                        18 U.S.C. 2257 Record-Keeping Requirements Compliance
                        Statement</a
                    ></em
                >
            </p>
        </div>
    </footer>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()

const companyName = computed(() =>
    store.getters['user/companyName'].split('').reverse().join('')
)
</script>
