import axios from 'axios'
import { addHours } from 'date-fns'

const state = () => {
    return {
        live: {
            babestation: [],
            streamate: [],
        },
        currentPage: 0,
        nextPage: 1,
        lastPage: null,
        totalResults: 0,
        filters: {},
        baseFilter: null, //used for category pages
        loadingModels: false,
        inWorldCams: false,
        smStreamKey: {
            key: null,
            expiresAt: null,
            blocked: false,
        },
    }
}

const getters = {
    streamateModels(state) {
        return state.live.streamate.map((m) => ({ ...m, type: 'sm' }))
    },
    babestationModels(state) {
        return state.live.babestation.map((m) => ({ ...m, type: 'bs' }))
    },
    filters(state) {
        return state.filters
    },
    isEndOfLiveCamResults(state) {
        return state.lastPage != null && state.lastPage <= state.currentPage
    },
    smStreamKey(state) {
        return state.smStreamKey
    },
}

const mutations = {
    clearFilters(state) {
        state.filters = []
    },
    clearBaseFilter(state) {
        state.baseFilter = null
    },
    setBaseFilter(state, filter) {
        state.baseFilter = filter
    },
    setInWorldCams(state, inWorldCams) {
        state.inWorldCams = inWorldCams
    },
    clearModels(state) {
        state.live.babestation = []
        state.live.streamate = []
        state.nextPage = 1
    },
    setBabestationModels(state, babestationModels) {
        state.live.babestation = babestationModels
    },
    setFilters(state, filters) {
        state.filters = filters
    },
    setStreamateModels(state, streamateModels) {
        state.live.streamate = streamateModels
    },
    incrementNextPage(state) {
        state.nextPage++
    },
    setCurrentPage(state, currentPage) {
        state.currentPage = currentPage
    },
    setLastPage(state, lastPage) {
        state.lastPage = lastPage
    },

    setTotalResults(state, totalResults) {
        state.totalResults = totalResults
    },
    setLoadingModels(state, loadingModels) {
        state.loadingModels = loadingModels
    },
    pushBabestationModels(state, babestationModels) {
        state.live.babestation.push(...babestationModels)
    },
    pushStreamateModels(state, streamateModels) {
        state.live.streamate.push(...streamateModels)
    },
    setSmStreamKey(state, smStreamKey) {
        state.smStreamKey = smStreamKey
    },
}

const actions = {
    fetchStreamateStreamKey: async ({ commit }) => {
        try {
            const { data } = await axios.get('/user/stream-key')

            //SM state streamkeys last for "at least 12 hours"
            const expiry = addHours(new Date(), 12).getTime()

            commit('setSmStreamKey', {
                key: data.sakey,
                expiresAt: expiry,
                blocked: false,
            })
        } catch (error) {
            //if there's an error the user other than "no credits". The user is blocked for some reason.
            if (
                error?.response?.data?.errors &&
                error.response.data.errors[0] !==
                    'User does not have an active card attached to their account'
            ) {
                commit('setSmStreamKey', {
                    key: null,
                    expires: null,
                    blocked: true,
                })
                return
            }
            commit('setSmStreamKey', {
                key: null,
                expires: null,
                blocked: false,
            })
        }
    },
    fetchModels: async ({ state, commit, rootGetters }) => {
        if (state.loadingModels) {
            return
        }

        commit('setLoadingModels', true)

        let filters = Object.keys(state.filters).filter(
            (key) => state.filters[key]
        )

        //check if a base filter is set, if it is add to the filters array
        if (state.baseFilter) {
            filters.push(state.baseFilter)
        }

        let perPage = 24

        //remove one result, so we have space to inject the new user offer
        if (
            !rootGetters['user/loggedIn'] ||
            rootGetters['user/hasNewUserOffer']
        ) {
            perPage = 23
        }

        const { data } = await axios.post('/live/cams/search', {
            page: state.nextPage,
            filters: filters,
            per_page: perPage,
        })

        commit('pushBabestationModels', data.babestation)

        commit('pushStreamateModels', data.streamate)

        commit('incrementNextPage')

        commit('setCurrentPage', data.pagination.current_page)

        commit('setLastPage', data.pagination.last_page)

        commit('setTotalResults', data.pagination.total)

        commit('setLoadingModels', false)

        return
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
