import axios from 'axios'
import Cookies from 'js-cookie'
import { formatSqlDate, formatIsoDate } from '@babestationui/vue/utils'

const namespace = 'user'
const persistedCacheKey = 'bs_user_state'
const defaultState = {
    info: {},
    topups: {
        data: [],
        next_cursor: null,
    },
    bonuses: {
        data: [],
        next_cursor: null,
    },
    credit_spend: {
        data: [],
        next_cursor: null,
    },
    pan4: null,
    auth_token: null,
    pusher_channel: null,
    countryCode: null,
}

const topupTypesToText = {
    CARD: 'Card',
    SMS: 'SMS',
    OPEN_BANKING: 'Bank',
}

const isServer = typeof window === 'undefined'

const state = () => {
    return isServer || !window.sessionStorage.getItem(persistedCacheKey)
        ? defaultState
        : JSON.parse(window.sessionStorage.getItem(persistedCacheKey))
}

const mutations = {
    setAuthToken(state, token) {
        state.auth_token = token
    },
    setUserInfo(state, info) {
        state.info = info
    },
    setTwoFaAuthStatus(state, status) {
        state.info.two_fa = status
    },
    setPan4(state, pan4) {
        state.pan4 = pan4
    },
    setPusherChannel(state, channel) {
        state.pusher_channel = channel
    },
    setBalance(state, balance) {
        state.info.balance = balance
    },
    bindEventToPusherChannel(state, { event, callback }) {
        state.pusher_channel.bind(event, callback)
    },
    toggleUserRebill(state) {
        state.info.rebill_enabled = !state.info.rebill_enabled
    },
    setTopups(state, topups) {
        state.topups = topups
    },
    setCreditSpend(state, creditSpend) {
        state.credit_spend = creditSpend
    },
    setBonuses(state, bonuses) {
        state.bonuses = bonuses
    },
    clearTopups(state) {
        state.topups = {
            data: [],
            next_cursor: null,
        }
    },
    clearBonuses(state) {
        state.bonuses = {
            data: [],
            next_cursor: null,
        }
    },
    clearCreditSpend(state) {
        state.credit_spend = {
            data: [],
            next_cursor: null,
        }
    },
    setCountryCode(state, countryCode) {
        state.countryCode = countryCode
    },
}

const actions = {
    setupAxiosAuthHeaders: (context, token) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        return
    },
    loginRequest: async (
        ctx,
        { username, password, recaptchaToken = null, v2RecaptchaToken = null }
    ) => {
        return await axios.post('/login', {
            username: username,
            password: password,
            recaptcha_token: recaptchaToken,
            v2_recaptcha_token: v2RecaptchaToken,
        })
    },

    registerRequest: async (
        { dispatch },
        {
            email,
            username,
            password,
            recaptchaToken = null,
            v2RecaptchaToken = null,
        }
    ) => {
        const { data } = await axios.post('/register', {
            email: email,
            username: username,
            password: password,
            recaptcha_token: recaptchaToken,
            v2_recaptcha_token: v2RecaptchaToken,
        })

        const { token } = data

        await dispatch('setupInitialToken', token)

        await dispatch('authenticate')

        return
    },

    setupInitialToken: async ({ commit, dispatch }, token) => {
        //create the cookie
        Cookies.set('babecall_token', token.access_token, {
            expires: 160, //let the token last 160 days
        })

        await dispatch('setupAxiosAuthHeaders', token.access_token)

        commit('setAuthToken', token.access_token)

        return
    },

    authenticate: async ({ commit, dispatch }) => {
        try {
            let { data } = await axios.get('/user/info')
            commit('setUserInfo', data.info)
        } catch (error) {
            //if this fails and is a 401 then logout the user and go to the return page or login page
            if (error.response.status == 401) {
                await dispatch('logout')
                return (window.location.href = '/login')
            }
        }

        return
    },
    changePassword: async ({ dispatch }, { currentPassword, newPassword }) => {
        const { data } = await axios.post('user/change-password', {
            current_password: currentPassword,
            new_password: newPassword,
        })

        await dispatch('setupInitialToken', data.token)

        return
    },

    sendPasswordResetEmail: async (ctx, { email }) => {
        return await axios.post('/reset-password-request', {
            email,
        })
    },
    sendUsernameReminder: async (ctx, { email }) => {
        return await axios.post('/username-reminder-request', {
            email,
        })
    },

    saveTwoFa: async ({ dispatch }, { twoFaOption, password }) => {
        await axios.post('/user/two-factor-auth/save', {
            status: twoFaOption,
            password: password,
        })

        await dispatch('updateUserInfo')

        return
    },
    updateUserInfo: async ({ commit, dispatch }) => {
        try {
            let { data } = await axios.get('/user/info')
            commit('setUserInfo', data.info)
        } catch (error) {
            //if this fails and is a 401 then logout the user and go to the return page or login page
            if (error.response.status == 401) {
                await dispatch('logout')
                window.location.href = '/login'
                return
            }
        }

        return
    },
    fetchPan4: async ({ commit }) => {
        const { data } = await axios.get('/user/pan4')
        commit('setPan4', data.t2_pan_4)
        return
    },
    fetchBalance: async ({ commit }) => {
        const { data } = await axios.get('/user/balance')
        commit('setBalance', data.balance)
        return
    },
    toggleRebill: async ({ commit }) => {
        const { data } = await axios.post('/user/toggle-rebill')

        if (data.success) {
            commit('toggleUserRebill')
        }

        return
    },
    setupPusherChannel: ({ commit, state }, pusher) => {
        const channel = pusher.subscribe(state.info.uuid)
        commit('setPusherChannel', channel)
        return
    },
    sendVerificationEmail: async () => {
        await axios.post('/user/resend-verification-email')
        return
    },

    requestPhoneNumberVerificationCode: async (
        ctx,
        { phoneNumber, countryCode }
    ) => {
        await axios.post('/user/add-phone', {
            country_code: countryCode,
            phone: phoneNumber,
        })

        return
    },

    verifyPhoneNumber: async (ctx, { code, password }) => {
        await axios.post('/user/verify-phone', {
            code: code,
            password: password,
        })
        return
    },
    resendPhoneVerificationCode: async () => {
        await axios.post('/user/resend-phone-code')
        return
    },
    subscribeToUserEvents: ({ commit, dispatch }) => {
        commit('bindEventToPusherChannel', {
            event: 'App\\Events\\BalanceUpdated',
            callback: (ev) => {
                commit('setBalance', ev.newBalance)
            },
        })

        commit('bindEventToPusherChannel', {
            event: 'App\\Events\\EmailVerified',
            callback: () => {
                dispatch('updateUserInfo')
            },
        })

        return
    },
    logout: async ({ commit }) => {
        //remove the state
        commit('setAuthToken', null)
        commit('setUserInfo', {})

        //remove session storage when added, this needs to be removed after any store changes
        //as they sync with session storage
        sessionStorage.removeItem(persistedCacheKey)
        return
    },

    async fetchTopups({ state, commit }) {
        let nextCursor = state.topups.next_cursor

        let url = '/user/transactions/topups'

        if (nextCursor) {
            url = url + '?cursor=' + nextCursor
        }

        const { data } = await axios.get(url)

        commit('setTopups', {
            data: [...state.topups.data, ...data.topups],
            next_cursor: data.next_cursor,
        })
    },

    async fetchCreditSpend({ state, commit }) {
        let nextCursor = state.credit_spend.next_cursor

        let url = '/user/transactions/credit-spend'

        if (nextCursor) {
            url = url + '?cursor=' + nextCursor
        }

        const { data } = await axios.get(url)
        commit('setCreditSpend', {
            data: [...state.credit_spend.data, ...data.credit_spend],
            next_cursor: data.next_cursor,
        })
    },
    async fetchBonuses({ state, commit }) {
        let nextCursor = state.bonuses.next_cursor

        let url = '/user/transactions/bonuses'

        if (nextCursor) {
            url = url + '?cursor=' + nextCursor
        }

        const { data } = await axios.get(url)
        commit('setBonuses', {
            data: [...state.bonuses.data, ...data.bonuses],
            next_cursor: data.next_cursor,
        })
    },
}

const getters = {
    balance(state) {
        return Math.floor(state.info.balance * 10)
    },
    worldCamsBalance(state) {
        return Math.round(state.info.balance * 100) / 100
    },
    creditSpend(state) {
        return state.credit_spend.data.map(function (spend) {
            const clone = { ...spend }
            clone.time = formatIsoDate(clone.time)
            return clone
        })
    },
    topups(state) {
        return state.topups.data.map(function (topup) {
            const clone = { ...topup }
            clone.type = topupTypesToText[clone.type]
            clone.time = formatSqlDate(clone.time)
            return clone
        })
    },
    bonuses(state) {
        return state.bonuses.data.map(function (bonus) {
            const clone = { ...bonus }
            clone.time = formatIsoDate(clone.time)
            return clone
        })
    },
    hasMoreTopups(state) {
        return !!state.topups.next_cursor
    },
    hasMoreCreditSpend(state) {
        return !!state.credit_spend.next_cursor
    },
    hasMoreBonuses(state) {
        return !!state.bonuses.next_cursor
    },
    loggedIn(state) {
        return !!state.auth_token
    },
    hasNewUserOffer(state) {
        return !!state.info.has_new_user_offer
    },
    companyName: (state) => {
        if (state.countryCode === 'GB') {
            return 'Playware Development Ltd'
        }

        return 'Cellcast UK LTD'
    },
}

export default {
    persistedCacheKey,
    namespace,
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
