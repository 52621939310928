export default function createPersistentState(vuexModule, replacer = null) {
    if (!vuexModule.persistedCacheKey) {
        throw new Error(
            'No cache key. Please set a cache key in the respective module.'
        )
    }

    if (!vuexModule.namespace) {
        throw new Error(
            'No namespace. Please set a namespace in the respective module.'
        )
    }

    return (store) => {
        store.subscribe((mutation, state) => {
            const namespacePattern = `${vuexModule.namespace}/`

            const isInModule = mutation.type.startsWith(namespacePattern)

            if (!isInModule) {
                return
            }

            window.sessionStorage.setItem(
                vuexModule.persistedCacheKey,
                JSON.stringify(state[vuexModule.namespace], replacer)
            )
        })
    }
}
