import { createApp, h, provide } from 'vue'
import axios from 'axios'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
import Pusher from 'pusher-js'
import useUserApi from './composables/useUserApi'

import '../css/app.css'

import { createStore, useStore } from 'vuex'

import { user, topup, livecams } from './stores'
import createPersistentState from './stores/plugins'
import { createInertiaApp, router } from '@inertiajs/vue3'

import MainLayout from './layouts/MainLayout.vue'

// eslint-disable-next-line no-undef
const debug = process.env.NODE_ENV !== 'production'

//replacer filters out the pusher channels, as it cannot be stringified
const createPersistentStatePlugin = createPersistentState(user, (key, value) =>
    key == 'pusher_channel' ? undefined : value
)

const { setupAuthenticatedUser } = useUserApi()

const store = createStore({
    modules: {
        user,
        topup,
        livecams,
    },
    strict: debug,
    plugins: [createPersistentStatePlugin],
})

window.Pusher = new Pusher(import.meta.env.VITE_PUSHER_KEY, {
    cluster: import.meta.env.VITE_PUSHER_CLUSTER,
})

createInertiaApp({
    progress: {
        color: '#e00e7a',
    },
    resolve: async (name) => {
        // /pages/Live/Streamate.vue
        const pages = import.meta.glob('./pages/**/*.vue')

        const page = await pages[`./pages/${name}.vue`]()

        if (name == 'Authenticate') {
            page.default.layout = null
            return page
        }

        //set default layout
        page.default.layout = page.default.layout || MainLayout

        return page
    },
    async setup({ el, App, props, plugin }) {
        createApp({
            render: () => h(App, props),
            setup() {
                const store = useStore()

                //in here we need to check if a user is already logged in but no token is set
                //check the cookie actually exists, and resync it with the store
                const authTokenCookie = props.initialPage.props.authToken

                //set the country code, so we can alter the site depending on the user's country
                store.commit(
                    'user/setCountryCode',
                    props.initialPage.props.countryCode
                )

                if (authTokenCookie) {
                    store.commit('user/setAuthToken', authTokenCookie)
                    store.commit(
                        'user/setUserInfo',
                        props.initialPage.props.userInfo
                    )
                }
            },
            beforeMount() {
                //don't need to do this server side as inertia handles that

                if (store.getters['user/loggedIn']) {
                    //setups axios header, pusher
                    setupAuthenticatedUser(store, window.Pusher)
                }
            },
        })
            .use(plugin)
            .use(store)
            .mount(el)
    },
})

if (process.env.NODE_ENV === 'production') {
    router.on('navigate', () => {
        //work around to track inertia views
        if (window.sendGaEvent && window.gtag) {
            gtag('js', new Date())
            gtag('config', 'G-WSS60JCN0E')
            gtag('config', 'UA-27075586-12')
        } else {
            window.sendGaEvent = true
        }
    })
}
