<template>
    <div class="2xl:container mx-auto dark:bg-slate-900">
        <DyanamicPopup />
        <Head>
            <title>{{ title }}</title>
            <meta name="description" :content="description" />
            <link rel="canonical" :href="canonicalUrl" />
        </Head>
        <NotificationsContainer />

        <div class="2xl:container mx-auto min-h-screen relative">
            <div class="sticky top-0 z-50">
                <NavbarDesktopBase class="hidden lg:block">
                    <template #nav-actions>
                        <div
                            class="flex lg:grid lg:grid-cols-7 xl:grid-cols-8 w-full px-4"
                        >
                            <NavbarDesktopItem
                                href="/"
                                class="mx-4 md:mx-1 h-11 pb-2 flex justify-center items-end"
                                mode="inertia"
                            >
                                Live HD Cams
                            </NavbarDesktopItem>
                            <NavbarDesktopItem
                                href="/our-babes"
                                class="mx-4 md:mx-1 h-11 pb-2 flex justify-center items-end"
                                mode="inertia"
                            >
                                Our Babes
                            </NavbarDesktopItem>
                            <NavbarDesktopItem
                                href="/videos"
                                class="mx-4 md:mx-1 h-11 pb-2 flex justify-center items-end"
                                mode="inertia"
                            >
                                Videos
                            </NavbarDesktopItem>

                            <NavbarDesktopItem
                                href="/schedule"
                                class="mx-4 md:mx-1 h-11 pb-2 flex justify-center items-end"
                                mode="inertia"
                            >
                                Schedule
                            </NavbarDesktopItem>

                            <NavbarDesktopItem
                                href="https://www.babestation.tv/vip"
                                class="mx-4 md:mx-1 h-11 pb-2 flex justify-center items-end"
                            >
                                VIP
                                <ExternalLink
                                    :height="3"
                                    :width="3"
                                    class="mb-2 ml-1 inline"
                                ></ExternalLink>
                            </NavbarDesktopItem>

                            <NavbarDesktopItem
                                href="https://www.babestation.tv/vip"
                                class="mx-4 md:mx-1 h-11 pb-2 xl:flex justify-center items-end hidden"
                            >
                                Sexting
                                <ExternalLink
                                    :height="3"
                                    :width="3"
                                    class="mb-2 ml-1 inline"
                                ></ExternalLink>
                            </NavbarDesktopItem>

                            <NavbarDesktopItem
                                href="#"
                                class="mx-4 md:mx-1 h-11 pb-2 flex justify-center items-end"
                                :hasDropdown="true"
                            >
                                More
                                <template #hoveredContent>
                                    <div
                                        class="w-fit divide-y items-center divide-gray-700 absolute top-11 right-1/2 left-1/2 -translate-x-1/2 bg-dark-brand px-2 py-2 flex flex-col justify-center"
                                    >
                                        <NavbarDesktopItem
                                            mode="inertia"
                                            href="/gallery"
                                            class="mx-4 md:mx-1 pb-2 xl:hidden justify-center items-end"
                                        >
                                            Sexting
                                            <ExternalLink
                                                :height="3"
                                                :width="3"
                                                class="mb-2 ml-1 inline"
                                            />
                                        </NavbarDesktopItem>

                                        <NavbarDesktopItem
                                            mode="inertia"
                                            href="/gallery"
                                            class="mx-4 md:mx-1 pb-2 flex justify-center items-end"
                                        >
                                            Gallery
                                        </NavbarDesktopItem>
                                        <NavbarDesktopItem
                                            href="https://www.babestation.tv/porn-games"
                                            class="mx-4 md:mx-1 pb-2 flex justify-center items-end"
                                        >
                                            Sex Games
                                            <ExternalLink
                                                :height="3"
                                                :width="3"
                                                class="mb-2 ml-1 inline"
                                            />
                                        </NavbarDesktopItem>
                                    </div>
                                </template>
                            </NavbarDesktopItem>

                            <div class="flex items-center">
                                <Button
                                    variant="rounded"
                                    class="!bg-darker-brand text-white hover:!ring-pink-bran px-2 max-w-lg w-full !text-left"
                                    @click="openSearchModal"
                                >
                                    <MagnifyingGlass
                                        :width="4"
                                        :height="4"
                                        class="inline mr-1"
                                    />
                                    Search...
                                </Button>
                                <SearchModal
                                    :is-open="showSearchModal"
                                    :search-results="searchResults"
                                    @close="closeSearchModal"
                                    @search="search"
                                    @goToIndex="goToIndex"
                                    @goToFullSearch="goToFullSearch"
                                    v-model="searchQuery"
                                    :loading="searching"
                                    :link-mode="'inertia'"
                                    :no-search-results="noSearchResults"
                                ></SearchModal>
                            </div>
                        </div>
                    </template>
                    <template #account-actions>
                        <DesktopAuthMenu
                            class="w-48 h-10 border-b border-transparent text-base"
                            v-if="loggedIn"
                        ></DesktopAuthMenu>
                        <DesktopAuthLinks
                            login-text="Login"
                            register-text="Register Free"
                            login-link="/login"
                            register-link="/register"
                            mode="inertia"
                            class="border-b h-12 border-transparent"
                            v-else
                        ></DesktopAuthLinks>
                    </template>
                </NavbarDesktopBase>
            </div>
            <div class="lg:hidden">
                <NavbarMobileBase ref="mobileNavbar">
                    <template #actions>
                        <button @click="openSearchModal" class="px-2">
                            <MagnifyingGlass
                                :width="7"
                                :height="7"
                                class="text-white"
                            />
                        </button>
                    </template>
                    <template #credits>
                        <div class="flex justify-center">
                            <AccountDetailsCreditsWidget
                                v-if="loggedIn"
                                :balance="balance"
                                :updating-balance="updatingBalance"
                                @update-balance="updateBalance"
                                :world-cams="inWorldCams"
                                class="ml-2 text-white text-xl my-2"
                            >
                            </AccountDetailsCreditsWidget>
                        </div>
                    </template>
                    <template #top-menu>
                        <Link
                            href="/login"
                            mode="inertia"
                            @finish="mobileNavbar.toggleMenu"
                            v-if="!loggedIn"
                        >
                            <RoundButtonIcon :icon="Login">
                                Login
                            </RoundButtonIcon>
                        </Link>
                        <Link
                            href="/register"
                            mode="inertia"
                            @finish="mobileNavbar.toggleMenu"
                            v-if="!loggedIn"
                        >
                            <RoundButtonIcon :icon="UserAdd">
                                Register
                            </RoundButtonIcon>
                        </Link>

                        <Link
                            href="/topup"
                            mode="inertia"
                            @finish="mobileNavbar.toggleMenu"
                            v-if="loggedIn"
                        >
                            <RoundButtonIcon :icon="ArrowCircleUpSolid">
                                Topup
                            </RoundButtonIcon>
                        </Link>
                        <Link
                            href="/my-account"
                            mode="inertia"
                            @finish="mobileNavbar.toggleMenu"
                            v-if="loggedIn"
                        >
                            <RoundButtonIcon :icon="UserSolid">
                                Profile
                            </RoundButtonIcon>
                        </Link>

                        <Link
                            href="/"
                            mode="inertia"
                            @finish="mobileNavbar.toggleMenu"
                        >
                            <RoundButtonIcon :icon="VideoCamera">
                                Live
                                <span
                                    class="inline-block rounded-full w-2 h-2 bg-green-400"
                                ></span>
                            </RoundButtonIcon>
                        </Link>
                    </template>
                    <template #nav-items>
                        <NavbarMobileSection class="mt-4">
                            Main
                        </NavbarMobileSection>
                        <NavbarMobileItem
                            href="/"
                            mode="inertia"
                            @finish="mobileNavbar.toggleMenu"
                        >
                            <template #icon> LIVE </template>
                            <template #text> Watch Live Shows </template>
                        </NavbarMobileItem>

                        <NavbarMobileItem
                            href="/our-babes"
                            mode="inertia"
                            @finish="mobileNavbar.toggleMenu"
                        >
                            <template #icon>
                                <Fire :height="5" :width="5"> </Fire>
                            </template>
                            <template #text> Our Babes </template>
                        </NavbarMobileItem>

                        <NavbarMobileItem
                            href="/videos"
                            mode="inertia"
                            @finish="mobileNavbar.toggleMenu"
                        >
                            <template #icon>
                                <VideoCamera :height="5" :width="5">
                                </VideoCamera>
                            </template>
                            <template #text> Videos </template>
                        </NavbarMobileItem>

                        <NavbarMobileItem
                            href="/schedule"
                            mode="inertia"
                            @finish="mobileNavbar.toggleMenu"
                        >
                            <template #icon>
                                <Calendar :height="5" :width="5" />
                            </template>
                            <template #text> Schedule</template>
                        </NavbarMobileItem>

                        <NavbarMobileSection class="mt-4">
                            More
                        </NavbarMobileSection>

                        <NavbarMobileItem
                            href="https://babestation.tv/vip"
                            mode="inertia"
                            @finish="mobileNavbar.toggleMenu"
                        >
                            <template #icon> VIP </template>
                            <template #text>
                                Babestation VIP
                                <ExternalLink
                                    :height="3"
                                    :width="3"
                                    class="mb-3 ml-1 inline"
                                />
                            </template>
                        </NavbarMobileItem>

                        <NavbarMobileItem
                            href="https://babestation.tv/blog/"
                            mode="inertia"
                            @finish="mobileNavbar.toggleMenu"
                        >
                            <template #icon>
                                <Newspaper :height="5" :width="5" />
                            </template>
                            <template #text>
                                Babestation Blog
                                <ExternalLink
                                    :height="3"
                                    :width="3"
                                    class="mb-3 ml-1 inline"
                                />
                            </template>
                        </NavbarMobileItem>
                    </template>
                </NavbarMobileBase>
            </div>

            <main class="py-12 lg:py-0 min-h-screen">
                <slot></slot>
            </main>

            <div class="z-50 relative">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>

<script setup>
import Footer from '../components/layout/Footer.vue'
import {
    NavbarDesktopBase,
    NavbarDesktopItem,
    NavbarMobileBase,
    NavbarMobileSection,
    NavbarMobileItem,
    NotificationsContainer,
    Link,
    Button,
    SearchModal,
    AccountDetailsCreditsWidget,
    RoundButtonIcon,
    useNotify,
} from '@babestationui/vue'

import {
    MagnifyingGlass,
    UserSolid,
    Fire,
    Login,
    UserAdd,
    VideoCamera,
    Calendar,
    ArrowCircleUpSolid,
    ExternalLink,
    Newspaper,
} from '@babestationui/vue/icons'

import DyanamicPopup from '../components/popups/DyanamicPopup.vue'

import DesktopAuthMenu from '../components/menu/DesktopAuthMenu.vue'
import DesktopAuthLinks from '../components/menu/DesktopAuthLinks.vue'

import { useStore } from 'vuex'
import { computed, ref, onMounted } from 'vue'
import axios from 'axios'
import { usePage, Head, router } from '@inertiajs/vue3'
import useSearchResults from '../composables/useSearchResults'

const { formatSearchResults } = useSearchResults()

const store = useStore()

const loggedIn = computed(() => store.getters['user/loggedIn'])

const mobileNavbar = ref(null)

const showSearchModal = ref(false)

const searchResults = ref([])

const searchQuery = ref('')

const searching = ref(false)

const noSearchResults = ref(false)

const openSearchModal = () => {
    showSearchModal.value = !showSearchModal.value
}

const closeSearchModal = () => {
    showSearchModal.value = false
    searchResults.value = []
}

const search = async () => {
    noSearchResults.value = false
    searching.value = true

    await axios
        .post('/search', {
            query: searchQuery.value,
        })
        .then((response) => {
            searchResults.value = formatSearchResults(response.data.results)

            if (response.data.results.length === 0) {
                noSearchResults.value = true
            }
        })

    searching.value = false
}

const canonicalUrl = computed(() => usePage().props.canonicalUrl)

const title = computed(() => usePage().props.title)
const description = computed(() => usePage().props.description)

const initialNotificaton = computed(() => usePage().props.notify)

const balance = computed(() =>
    inWorldCams.value
        ? store.getters['user/worldCamsBalance']
        : store.getters['user/balance']
)

const updatingBalance = ref(false)

const inWorldCams = computed(() => {
    return store.state.livecams.inWorldCams
})

const updateBalance = async () => {
    updatingBalance.value = true

    await store.dispatch('user/fetchBalance')

    updatingBalance.value = false
}

const { notify } = useNotify()

onMounted(async () => {
    if (initialNotificaton.value) {
        notify(initialNotificaton.value)
    }
})

const goToIndex = async (indexName) => {
    router.visit(`/search/${indexName}?query=${searchQuery.value}`)
    showSearchModal.value = false
}

const goToFullSearch = async () => {
    router.visit(`/search?query=${searchQuery.value}`)
    showSearchModal.value = false
}
</script>
