<template>
    <ImageModal
        v-if="activePopup && activePopup.showShouldPopup"
        :is-open="!!activePopup"
        class="p-0"
        container-classes="max-w-xl"
        @close="() => (activePopup = null)"
    >
        <Link
            :href="activePopup.link"
            :class="`dynamic-popup ${activePopup.title} w-full`"
            mode="inertia"
            @before="reportToGa"
            @click="() => (activePopup = null)"
        >
            <div class="aspect-w-4 aspect-h-3">
                <img :src="activePopup.image" class="w-full" />
            </div>
        </Link>
    </ImageModal>
</template>

<script setup>
import { onMounted, ref, computed, watch } from 'vue'
import { ImageModal, Link, usePopupTimer } from '@babestationui/vue'
import axios from 'axios'
import { useStore } from 'vuex'

const store = useStore()

const isLoggedIn = computed(() => store.getters['user/loggedIn'])
const activePopup = ref(null)

const defaultPopup = {
    id: 100001,
    title: 'Registration: Tanya & Hannah',
    link: '/register',
    site: '5',
    show_after: 60,
    show_again: 6,
    image: 'https://cdn.babestation.tv/popups/f58b371a139b2f0e7ab951e543b84e0a/e606e58aa2a94c2dcd58020b84ede0dc.jpg',
}

const fetchDynamicPopup = async () => {
    const { data } = await axios.get('/popup')

    return data.popup
}

const setupPopup = (popup) => {
    //add the popup
    activePopup.value = popup

    //set the timer
    const { shouldShowPopup } = usePopupTimer(popup)

    //watch until the timer is done
    watch(shouldShowPopup, (val) => {
        //add the should show popup attribute
        activePopup.value.showShouldPopup = val
    })
}

onMounted(async () => {
    const popup = await fetchDynamicPopup()

    //if the user is not logged in and there is no popup go back to default
    if (!popup && !isLoggedIn.value) {
        setupPopup(defaultPopup)
        return
    }

    if (popup) {
        setupPopup(popup)
    }
})

const reportToGa = () => {
    if (activePopup.value && window.gtag) {
        gtag('event', 'popup_click', {
            event_category: 'clicks',
            event_label: activePopup.value.title,
        })
    }

    return true
}
</script>
