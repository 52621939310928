import { useCookies } from '@vueuse/integrations/useCookies'
import axios from 'axios'
import { addBearerTokenToAxios } from '@babestationui/vue/utils'
import { useUserInfoApi, useUserBalanceApi } from '@babestationui/vue'

const cookies = useCookies()
const { fetchUserInfo, fetchPan4 } = useUserInfoApi(axios)

const { fetchingBalance, fetchBalance } = useUserBalanceApi(axios)

const loadUserInfo = async (store) => {
    const { data, errors } = await fetchUserInfo('/user/info')

    if (errors && errors.includes('User is not authenticated.')) {
        await logout(store)
    }

    store.commit('user/setUserInfo', data.info)

    return
}

const logout = async (store) => {
    await store.dispatch('user/logout')

    cookies.remove('babecall_token', {
        domain: import.meta.env.VITE_APP_DOMAIN,
        path: '/',
    })

    return (window.location.href = window.returnTo || '/login')
}

const setupAuthToken = (token, store) => {
    cookies.set('babecall_token', token, {
        domain: import.meta.env.VITE_APP_DOMAIN, //make sure it's on the root domain.
        expires: new Date(new Date().getTime() + 160 * 24 * 60 * 60 * 1000), //let the token last 160 days
        path: '/',
    })

    store.commit('user/setAuthToken', token)

    addBearerTokenToAxios(axios, store.state.user.auth_token.access_token)
}

const setupAuthenticatedUser = (store, pusher) => {
    addBearerTokenToAxios(axios, store.state.user.auth_token.access_token)

    //setup user channel
    store.dispatch('user/setupPusherChannel', pusher)

    //subscribe to completed topup channels
    store.dispatch('topup/subscribeToCompletedTopups')

    store.dispatch('user/subscribeToUserEvents')

    loadUserInfo(store)
}

const updateBalance = async (store) => {
    if (fetchingBalance.value) {
        return
    }

    const { data } = await fetchBalance()

    if (data) {
        store.commit('user/setBalance', data.balance)
    }
}

const updatePan4 = async (store) => {
    const { data } = await fetchPan4()

    store.commit('user/setPan4', data.t2_pan_4)
}

export default function useUserApi() {
    return {
        loadUserInfo,
        setupAuthToken,
        setupAuthenticatedUser,
        logout,
        updateBalance,
        updatingBalance: fetchingBalance,
        updatePan4,
    }
}
