import axios from 'axios'

const state = () => {
    return {
        creditCardPackages: [],
        openBankingPackages: [],
        smsPackages: [],
        completedCardTopups: [],
        completedSmsTopups: [],
    }
}

const mutations = {
    setCreditCardPackages(state, packages) {
        state.creditCardPackages = packages
    },
    setOpenBankingPackages(state, openBankingPackages) {
        state.openBankingPackages = openBankingPackages
    },
    setSmsPackages(state, smsPackages) {
        state.smsPackages = smsPackages
    },
    pushToCompletedCardTopups(state, completedTopup) {
        state.completedCardTopups.push(completedTopup)
    },
    pushToCompletedSmsTopups(state, completedTopup) {
        state.completedSmsTopups.push(completedTopup)
    },
}

const actions = {
    async fetchCreditCardPackages({ commit }, promocode) {
        const { data } = await axios.get('/user/topup/packages', {
            params: {
                promocode,
            },
        })

        commit('setCreditCardPackages', data.packages)

        return
    },
    async fetchOpenBankingPackages({ commit }) {
        const { data } = await axios.get('/user/topup/packages/open-banking')
        commit('setOpenBankingPackages', data.packages)

        return
    },
    async fetchSmsPackages({ commit }) {
        const { data } = await axios.get('/user/topup/packages/sms')
        commit('setSmsPackages', data.packages)
        return
    },
    async fetchCardTopupUrl(
        ctx,
        { amount, redirectURL, usePreviousCard = false, promocode = null }
    ) {
        var { data } = await axios.post('/user/topup', {
            rebill: usePreviousCard,
            amount,
            promocode: promocode,
            redirect_url: redirectURL,
        })

        return data
    },
    async fetchOpenBankingTopupUrl(ctx, { packageId, returnUrl }) {
        var { data } = await axios.post('/user/topup/open-banking', {
            package_id: packageId,
            return_to_url: returnUrl,
        })

        return data
    },
    async subscribeToCompletedTopups({ commit, dispatch }) {
        commit(
            'user/bindEventToPusherChannel',
            {
                event: 'App\\Events\\CompletedTopup',
                callback: (ev) => {
                    commit('pushToCompletedCardTopups', ev)
                    dispatch('user/fetchPan4', null, { root: true })
                },
            },
            { root: true }
        )

        commit(
            'user/bindEventToPusherChannel',
            {
                event: 'App\\Events\\CompletedTopup',
                callback: (ev) => {
                    commit('pushToCompletedSmsTopups', ev)
                },
            },
            { root: true }
        )
    },
}

const getters = {
    openBankingPackages(state) {
        return state.openBankingPackages.map((pack) => {
            const credit = pack.credit * 10
            return {
                id: pack.id,
                credit,
                creditWithBonuses: credit,
                cost: pack.cost,
                name: pack.name,
                bonuses: [], //don't have bonuses yet
            }
        })
    },
    smsPackages(state) {
        return state.smsPackages.map((pack) => {
            const credit = pack.credit * 10
            return {
                credit,
                creditWithBonuses: credit,
                cost: pack.cost,
                name: pack.name,
                bonuses: [], //SMS purchases don't have bonuses
            }
        })
    },
    creditCardPackagesHavePromocodeApplied(state) {
        return !!state.creditCardPackages.find((pack) =>
            pack.bonuses.find((bonus) => bonus.promocode)
        )
    },
    creditCardPackages(state) {
        return state.creditCardPackages.map((pack) => {
            const credit = pack.credit * 10
            const creditWithBonuses =
                credit +
                pack.bonuses.reduce(
                    (total, bonus) => (total += bonus.credit * 10),
                    0
                )
            const bonuses = pack.bonuses.map((bonus) => {
                const credit = bonus.credit * 10

                const name = bonus.promocode ? `Promocode applied.` : bonus.name

                return {
                    credit,
                    name,
                    promocode: bonus.promocode,
                }
            })
            return {
                credit,
                creditWithBonuses,
                cost: pack.cost,
                name: pack.name,
                bonuses: bonuses,
            }
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
