import { formatSearchResults as utilsFormatSearchResults } from '@babestationui/vue/utils'

export default function useSearchResults() {
    const formatSearchResults = (results) =>
        utilsFormatSearchResults(results, {
            streamate_profiles: {
                linkFn: (hit) => {
                    return `/live/sm/${hit.nickname}`
                },
            },
            live_babestation: {
                linkFn: (hit) => {
                    return `/live/bs/${hit.operator_id}/${hit.slug}`
                },
            },
            babestation_video: {
                linkFn: (hit) => {
                    return `/videos/${hit.elevated_x_reference_id}/${hit.slug}`
                },
            },
            galleries: {
                linkFn: (hit) => {
                    return `/gallery/${hit.slug}`
                },
            },
            babestation_com_profiles: {
                linkFn: (hit) => {
                    return `/our-babes/${hit.slug}`
                },
            },
            custom_pages: {
                linkFn: (hit) => {
                    return `/${hit.full_path}`
                },
            },
            shifts: {
                linkFn: (hit) => {
                    return `/schedule?date=${hit.date}`
                },
            },
            shows: {
                linkFn: (hit) => {
                    return `/schedule?date=${hit.date}`
                },
            },
        })

    return {
        formatSearchResults,
    }
}
